// External imports
import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// Local imports
import App from './app'
import initI18n from './lang'
import './styles/main.scss'

Sentry.init({
  dsn: 'https://0a9af7e0c603b5ffdff75c070b84403d@o4504538423164928.ingest.us.sentry.io/4508574962679808',

  // This enables automatic instrumentation (highly recommended)
  // If you only want to use custom instrumentation:
  // * Remove the `BrowserTracing` integration
  // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
  integrations: [
    Sentry.browserTracingIntegration(),
    // Or, if you are using react router, use the appropriate integration
    // See docs for support for different versions of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    //Sentry.reactRouterV6BrowserTracingIntegration({
    //useEffect: React.useEffect,
    //useLocation,
    //useNavigationType,
    //createRoutesFromChildren,
    //matchRoutes,
    //}),
  ],

  // For finer control of sent transactions you can adjust this value, or
  // use tracesSampler
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
})

// Global initialization
initI18n()

const htmlRoot = document.getElementById('root') as HTMLElement
const reactRoot = ReactDOM.createRoot(htmlRoot)

reactRoot.render(
  process.env.REACT_APP_SCRICT_MODE === 'true' ? (
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  ) : (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  ),
)
