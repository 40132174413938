import { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { useSpeech, SpeechSynthesisStatus } from '../../context/speechSynthesisProvider'
import httpClient from '../../util/http-client'

export const AudioPlayer = () => {
  const { status, setStatus, message } = useSpeech()
  const [url, setUrl] = useState('')

  const prepareMessage = (msg: string) => {
    return msg
      .replace(/<[^>]*>?/gm, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/_/g, '.')
  }

  const streamMessage = () => {
    const prepared = prepareMessage(message)
    httpClient
      .stream('user/voice', {
        message: prepared,
      })
      .then((response) => response.blob())
      .then((blob) => {
        setUrl(URL.createObjectURL(blob))
      })
  }

  useEffect(() => {
    if (!message) {
      return
    }
    setUrl('')
    streamMessage()
  }, [message])

  return (
    <>
      <ReactPlayer
        url={url}
        width="0"
        style={{ display: 'none' }}
        onReady={() => {
          setStatus(SpeechSynthesisStatus.PLAYING)
        }}
        onEnded={() => {
          setStatus(SpeechSynthesisStatus.STOPPED)
        }}
        playing={status === SpeechSynthesisStatus.PLAYING}
      />
    </>
  )
}
