import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { CircularProgress } from '@mui/material'
import { useSpeech } from '../../context/speechSynthesisProvider'

type Props = {
  message: string
  loadingResponse: boolean
  messageId: number | undefined
  showSpinner?: boolean
  showPlayButton?: boolean
}

function BotMessage({ message, loadingResponse, messageId, showSpinner = false, showPlayButton = false }: Props) {
  const { speak, pause, isPlaying, isPlayingAnotherMessage } = useSpeech()
  return (
    <>
      {(loadingResponse || showSpinner) && <CircularProgress />}
      {!loadingResponse && (
        <div style={{ textAlign: 'left' }}>
          {message}

          {showPlayButton && (
            <span style={{ float: 'right', marginTop: '10px' }}>
              {isPlayingAnotherMessage(messageId || 0) && <PlayDisabledIcon />}
              {!isPlayingAnotherMessage(messageId || 0) && (
                <>
                  {isPlaying(messageId || 0) && (
                    <PauseCircleOutlineIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        pause(messageId || 0)
                      }}
                    />
                  )}
                  {!isPlaying(messageId || 0) && (
                    <PlayCircleOutlineIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => speak(message, messageId || 0)}
                    />
                  )}
                </>
              )}
            </span>
          )}
        </div>
      )}
    </>
  )
}

export default BotMessage
