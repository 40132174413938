import { ChatController, MuiChat } from 'chat-ui-react'
import { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import { Alert, LinearProgress } from '@mui/material'
import BotMessage from './botMessage'
import delay from 'delay'
import httpClient from '../../util/http-client'
import { AudioPlayer } from '../AudioPlayer'

type ResponseChunk = {
  index: number
  message: string
}

const sortResponseChunks = (responseChunk: ResponseChunk[]) => {
  return responseChunk.sort((a: ResponseChunk, b: ResponseChunk) => a.index - b.index)
}

type SetProgressType = React.Dispatch<React.SetStateAction<number>>

type Props = {
  onUpdate: () => void
  onProgress: SetProgressType
}

export interface ChatHandle {
  onEndSession: () => void
}

const Chat = forwardRef<ChatHandle, Props>(function Chat({ onUpdate, onProgress }, ref) {
  const [chatCtl] = useState(new ChatController())
  const [responseChunk, setResponseChunk] = useState<ResponseChunk[]>([])
  const [currMessage, setCurrMessage] = useState<string>('')
  const [messageId, setMessageId] = useState<number | undefined>(undefined)
  const [userAnswer, setUserAnswer] = useState<string>('')
  const [eventSource, setEventSource] = useState<EventSource | null>(null)
  const [userName] = useState<string>(sessionStorage.getItem('name') as string)
  const [error, setError] = useState<{ message: string } | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loadingResponse, setLoadingResponse] = useState<boolean>(false)
  const [waitingForFirstChunk, setWaitingForFirstChunk] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [messageComplete, setMessageComplete] = useState<boolean>(false)

  const onStartResponse = async () => {
    setLoadingResponse(true)
    setWaitingForFirstChunk(true)
    setMessageComplete(false)
    setResponseChunk([])
    const msgId = await chatCtl.addMessage({
      type: 'jsx',
      content: (
        <>
          <BotMessage
            message={currMessage}
            loadingResponse={loadingResponse}
            messageId={messageId}
            showSpinner={waitingForFirstChunk}
          />
        </>
      ),
      self: false,
    })
    setMessageId(msgId)
  }

  const onResponseChunk = (chunk: ResponseChunk) => {
    setWaitingForFirstChunk(false)
    setResponseChunk((prev) => [...prev, chunk])
  }

  const setupNextInput = async () => {
    try {
      // Cancel any existing action request first
      chatCtl.cancelActionRequest()
      await delay(100) // Brief delay to let the UI update

      const answer = await chatCtl.setActionRequest({
        type: 'text',
        placeholder: 'Type your message...',
      })
      setMessageComplete(true) // Set message as complete when input is ready
      if (answer && answer.value) {
        setUserAnswer(answer.value)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to setup next input:', err)
      // Retry once after a longer delay
      await delay(500)
      try {
        const answer = await chatCtl.setActionRequest({
          type: 'text',
          placeholder: 'Type your message...',
        })
        setMessageComplete(true) // Set message as complete when input is ready on retry
        if (answer && answer.value) {
          setUserAnswer(answer.value)
        }
      } catch (retryError) {
        console.error('Failed to setup input after retry:', retryError)
      }
    }
  }

  const handleStreamEnd = async () => {
    setMessageComplete(true)

    // Fetch costs
    try {
      const response = await httpClient.post('user/costs', {})
      onProgress(response.costPercentage)
    } catch (err) {
      console.error('Error fetching costs:', err)
    }

    // Update UI state
    await delay(200) // Give time for state updates
    setLoadingResponse(false)

    // Setup for next interaction
    await setupNextInput()
    setMessageId(undefined)
    setResponseChunk([])
    onUpdate()
  }
  const setupMessageStream = async (sid: string, message: string) => {
    try {
      const response = await fetch(`/api/chat/stream/${sid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      setLoadingResponse(false)
      onStartResponse()

      const reader = response.body?.getReader()
      if (!reader) throw new Error('No reader available')

      const decoder = new TextDecoder()

      const processStream = async () => {
        while (true) {
          const { done, value } = await reader.read()

          if (done) {
            reader.cancel()
            return true
          }

          const chunk = decoder.decode(value)
          const lines = chunk.split('\n')

          for (const line of lines) {
            if (!line.trim()) continue

            if (line.startsWith('event: end')) {
              const endData = line.includes('data:') ? line.split('data: ')[1].trim() : ''
              if (endData === 'complete') {
                await handleStreamEnd()
              }
              return true
            }

            if (!line.startsWith('data: ')) continue

            const data = line.slice(6)
            if (data === 'connected') continue

            try {
              const parsed = JSON.parse(data)
              if (parsed && parsed.index !== undefined) {
                onResponseChunk(parsed)
              }
            } catch (e) {
              console.debug('Skipping unparseable chunk:', data)
            }
          }
        }
      }

      const streamComplete = await processStream()
      if (streamComplete) {
        await handleStreamEnd()
      }
    } catch (err) {
      setError({ message: 'Connection lost' })
      setEventSource(null)
    }
  }

  const onEndSession = async () => {
    chatCtl.cancelActionRequest()
    if (sessionId) {
      await httpClient.post('chat/end', { sessionId })
      eventSource?.close()
      setEventSource(null)
      setSessionId(null)
    }
  }

  useImperativeHandle(ref, () => ({
    onEndSession,
  }))

  useEffect(() => {
    setLoadingResponse(false)

    const fullMessage = sortResponseChunks(responseChunk)
      .map((chunk) => (chunk.message ? chunk.message : ''))
      .join('')
    setCurrMessage(fullMessage)
    if (messageId !== undefined) {
      chatCtl.updateMessage(messageId, {
        type: 'jsx',
        content: (
          <>
            <BotMessage
              message={currMessage}
              loadingResponse={loadingResponse}
              messageId={messageId}
              showSpinner={waitingForFirstChunk}
              showPlayButton={messageComplete}
            />
          </>
        ),
        self: false,
      })
      onUpdate()
    }
  }, [chatCtl, currMessage, messageId, responseChunk, loadingResponse, onUpdate])

  useEffect(() => {
    if (userAnswer && sessionId) {
      setupMessageStream(sessionId, userAnswer)
      setUserAnswer('')
    }
  }, [userAnswer, sessionId])

  useEffect(() => {
    const initializeChat = async () => {
      if (!userName || sessionId) return

      try {
        const response = await httpClient.post('chat/session', {})
        setSessionId(response.sessionId)
        await setupMessageStream(response.sessionId, 'Start het gesprek')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.message === 'Invalid token') {
          setErrorMessage('Je bent afgemeld. Log opnieuw in met een geldige code.')
        } else {
          setErrorMessage(err.message)
        }
        setError({ message: err.message })
      }
    }

    initializeChat()
    return () => {
      eventSource?.close()
    }
  }, [userName, sessionId])

  // Only one component used for display
  return (
    <>
      {error && (
        <Alert severity="error">
          <strong>Error:</strong> {errorMessage}
        </Alert>
      )}
      {sessionId && (
        <>
          <MuiChat chatController={chatCtl} />
          <AudioPlayer />
        </>
      )}

      {!sessionId && !error && (
        <>
          <LinearProgress color="secondary" />
        </>
      )}
    </>
  )
})

export default Chat
