/* eslint-disable @typescript-eslint/no-explicit-any */
class HttpClient {
  // Define the base URL for the API.
  private readonly BASE_URL = window.location.origin

  async post(endpoint: string, data: any): Promise<any> {
    const response = await fetch(`${this.BASE_URL}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return response.json()
  }

  async stream(endpoint: string, data: any): Promise<any> {
    const response = await fetch(`${this.BASE_URL}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return response
  }

  async unauthenticatedPost(endpoint: string, data: any): Promise<any> {
    const response = await fetch(`${this.BASE_URL}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return response.json()
  }
}

export default new HttpClient()
