/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import httpClient from '../../util/http-client'
import { Alert, Rating } from '@mui/material'
import { Copyright } from '../../components/Copyright/Copyright'
import { InfoDialog } from '../../components/InfoDialog/InfoDialog'

type LoginData = {
  code: string
  username: string
  skillScore: number
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme()

export default function SignInSide() {
  const [nameError, setNameError] = React.useState(false)
  const [codeFail, setCodeFail] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [enableNextStep, setEnableNextStep] = React.useState(false)
  const [loginData, setLoginData] = React.useState<LoginData>({
    code: '',
    username: '',
    skillScore: 0,
  })
  const random = React.useMemo<number>(() => {
    return Math.floor(Math.random() * 3) + 1
  }, [])
  const navigate = useNavigate()

  const handleOpenInfoDialog = () => {
    setDialogOpen(true)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (activeStep === steps.length - 1) {
      login()
      return
    }
    const onLoad = steps[activeStep + 1]?.onLoad
    if (onLoad) {
      onLoad()
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    const onLoad = steps[activeStep - 1]?.onLoad
    if (onLoad) {
      onLoad()
    }
  }

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault()
    const code = event.currentTarget.value
    setLoginData({ ...loginData, code: code })
    if (code.length < 6) {
      setEnableNextStep(false)
      return
    }
    httpClient
      .unauthenticatedPost('auth/code-valid', {
        code: code as string,
      })
      .then((response) => {
        if (response.code === 'valid') {
          setCodeFail(false)
          setEnableNextStep(true)
        } else {
          setEnableNextStep(false)
          setCodeFail(true)
        }
      })
      .catch((_error) => {
        setEnableNextStep(false)
        setCodeFail(true)
      })
  }

  const login = () => {
    httpClient
      .unauthenticatedPost('auth/login', {
        name: loginData.username,
        code: loginData.code,
        skillScore: loginData.skillScore,
      })
      .then((response) => {
        sessionStorage.setItem('token', response.token)
        sessionStorage.setItem('name', loginData.username as string)
        sessionStorage.setItem('skillScore', `${loginData.skillScore}`)
        setLoginData({ ...loginData, code: '', username: '', skillScore: 0 })
        navigate('/chat')
      })
      .catch((_error) => {
        setCodeFail(true)
      })
  }

  const steps = [
    {
      label: 'Vul je unieke code in',
      description: `Deze code heb je nodig om een gesprek te voeren met PIM. Per code kan je 1 gesprek voeren. Wil je ook een code? Vraag het bij Alkmaar Taal Thuis.`,
      onLoad: () => {
        if (loginData.code && !codeFail) {
          setEnableNextStep(true)
        }
      },
      content: (
        <>
          {codeFail && <Alert severity="error">De code is niet geldig meer of incorrect, vraag een nieuwe aan.</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="Wat is de PIM-Code?"
            name="code"
            autoComplete="code"
            autoFocus
            value={loginData.code}
            onChange={(e) => {
              handleCodeChange(e)
            }}
          />
        </>
      ),
    },
    {
      label: 'Vul je naam in',
      description: ``,
      onLoad: () => {
        if (!loginData.username) {
          setEnableNextStep(false)
        }
      },
      content: (
        <>
          {nameError && <Alert severity="error">Vul je naam in</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Wat is je naam?"
            name="name"
            autoComplete="name"
            value={loginData.username}
            autoFocus
            onChange={(e) => {
              setLoginData({ ...loginData, username: e.currentTarget.value })
              if (!e.currentTarget.value) {
                setNameError(true)
                setEnableNextStep(false)
                return
              }
              setEnableNextStep(true)
              setNameError(false)
            }}
          />
        </>
      ),
    },
    {
      label: 'Hoe goed is je Nederlands?',
      description: ``,
      onLoad: () => {
        if (loginData.skillScore) {
          setEnableNextStep(true)
        } else {
          setEnableNextStep(false)
        }
      },
      content: (
        <>
          <Rating
            sx={{ marginTop: '20px' }}
            size="large"
            name="simple-controlled"
            value={loginData.skillScore}
            onChange={(_event, newValue) => {
              setLoginData({ ...loginData, skillScore: newValue ? newValue : 0 })
              if (newValue === null) {
                setEnableNextStep(false)
                return
              }
              setEnableNextStep(true)
            }}
          />
        </>
      ),
    },
  ]

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(images/alkmaar-${random}.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <SmartToyIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              PIM
            </Typography>
            <Alert severity="info">
              Gooi je code niet weg! <br />
              Je kan je code gebruiken tot het tegoed op is!
            </Alert>
            <Box component="form" noValidate sx={{ width: 350, mt: 1 }}>
              <Stepper activeStep={activeStep} orientation="vertical" sx={{ marginBottom: '30px' }}>
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <Typography variant="caption">{step.description}</Typography>
                      {step.content}
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {index > 0 && (
                            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                              Terug
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            disabled={!enableNextStep}
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === steps.length - 1 ? 'Praat met PIM' : 'Doorgaan'}
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              <Box
                sx={{
                  my: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <iframe
                  src="https://www.youtube.com/embed/ONJC4II4Es4?si=zWkdDX5-90fihexm"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Box>
              <InfoDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
              <Button variant="outlined" fullWidth onClick={handleOpenInfoDialog}>
                Meer informatie over PIM
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
